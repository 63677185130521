import http from '@/utils/http'

// 添加 修改求职信息

export const postResumes = (params) => {
    return http.post('/reeresume/addorupdateresume', params)
}
export const addorupdatejobexpect = (params) => {
    return http.post('/reeresume/addorupdatejobexpect', params)
}
export const deljobexpect = (id) => {
    return http.post('/reeresume/deljobexpect?id=' + id)
}
// 职业类别

export const ExpectJobs = () => {
    return http.get('/reetalentsinvite/gettalentsoptions')
}
export const ExpectJobs1 = (code) => {
    return http.get('/reetalentsinvite/gettalentsoptions?cityCode=' + code)
}
// 验证码

export const phone = (params) => {
    return http.get('/reeresume/sendverifyphonesms', params)
}

